import React, { Fragment, useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { APIClient } from "../../helpers/api_helper";
import { ListGroup, ListGroupItem, Collapse, NavbarToggler, NavLink, Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Label, Modal, ModalBody, Offcanvas, OffcanvasBody, Row, UncontrolledDropdown, FormFeedback, ModalHeader } from "reactstrap";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";

import moment from "moment";
import "moment-timezone";

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import tiktok from "../../assets/images/tiktok.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactHtmlParser from 'react-html-parser';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";

// Import Images
import logodark from "../../assets/images/logo-dark.png";
import logoSM from "../../assets/images/logo-sm.png";
import logolight from "../../assets/images/logo-light.png";
import defaultDemo from "../../assets/images/mid_banner.jpg";

import Contact from './contact';
import Cta from './cta';
import Footer from '../footer';


// Import Images
import img1 from "../../assets/images/small/img-1.jpg";
import { getContent, resetContentState } from "../../store/content/action"
import { getBanner, resetBannerState } from "../../store/banner/action"
import { getNews, resetNewsState } from "../../store/news/action"
import { getAssetFeature } from "../../store/assetFeature/action";
import { getClient, resetClientState, } from "../../store/client/action";
import { getAssetLocation, resetAssetLocationState } from "../../store/assetLocation/action";
import { getAssetCategory, resetAssetCategoryState } from "../../store/assetCategory/action";
import { getAssetTag, addNewAssetTag } from "../../store/assetTag/action";
import { getAssetClass, addNewAssetClass } from "../../store/assetClass/action";
import { getAsset, resetAssetState } from "../../store/asset/action";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

SwiperCore.use([FreeMode, Navigation, Thumbs]);


const Index = () => {
    const { assetId } = useParams();
    const [isSearch, setIsSearch] = useState(false);
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);

    const {
        assets,
        loading,
        assetCategories,
        assetCategoryLoading,
        assetTags,
        assetTagLoading,
        clients,
        clientLoading,
        assetLocations,
        assetLocationLoading,
        assetClasses,
        assetClassLoading,
        assetFeatures,
        assetFeatureLoading,
        contents,
        contentLoading,
        newss,
        newsLoading,
        banners,
        bannerLoading } = useSelector((state) => ({
            assetAdded: state.Asset.assetAdded,
            assets: state.Asset.assets,
            newss: state.News.newss,
            newsLoading: state.News.loading,
            banners: state.Banner.banners,
            bannerLoading: state.Banner.loading,
            contents: state.Content.contents,
            contentLoading: state.Content.loading,
            isAssetCreated: state.Asset.isAssetCreated,
            isAssetSuccess: state.Asset.isAssetSuccess,
            loading: state.Asset.loading,
            clients: state.Client.clients,
            clientLoading: state.Client.loading,
            assetCategories: state.AssetCategory.assetCategories,
            assetCategoryLoading: state.AssetCategory.loading,
            users: state.User.users,
            userLoading: state.User.loading,
            assetTags: state.AssetTag.assetTags,
            assetTagLoading: state.AssetTag.loading,
            assetFeatures: state.AssetFeature.assetFeatures,
            assetFeatureLoading: state.AssetFeature.loading,
            assetClasses: state.AssetClass.assetClasses,
            assetClassLoading: state.AssetClass.loading, assetLocations: state.AssetLocation.assetLocations,
            isAssetLocationCreated: state.AssetLocation.isAssetLocationCreated,
            isAssetLocationSuccess: state.AssetLocation.isAssetLocationSuccess,
            assetLocationLoading: state.AssetLocation.loading,
        }));

    const [isOpenMenu, setisOpenMenu] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [navClass, setnavClass] = useState("");
    const [currentImage, setCurrentImage] = useState("");
    const [asset, setAsset] = useState([]);

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [swiperActiveTab, setSwiperActiveTab] = useState("1");

    const toggle = () => setisOpenMenu(!isOpenMenu);

    const toggleModal = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    useEffect(() => {
        if (!isSearch) {
            dispatch(resetAssetState());
            dispatch(resetClientState());
            dispatch(resetNewsState());
            dispatch(resetBannerState());
            dispatch(resetContentState());
            dispatch(getAsset({ asset: assetId }));
            dispatch(getNews({ status: 2 }));
            dispatch(getBanner({ category: "Location Banner", status: 2 }));
            dispatch(getContent());
            dispatch(getClient({status:2}));
            dispatch(getAssetTag());
            dispatch(getAssetFeature());
            dispatch(getAssetClass());
            dispatch(getAssetLocation({ detail: assetId }));
            setIsSearch(true);
        }
    }, [dispatch, assetId, isSearch]);


    const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("is-sticky");
        } else {
            setnavClass("");
        }
    }

    document.title = " Abdihome - About Us";

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        const element = document.getElementById("back-to-top");
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    };

    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    function currencyFormat(num) {
        return 'Rp ' + parseFloat(num).toLocaleString(['ban', 'id'])
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggledropDown = () => {
        setDropdownOpen(!dropdownOpen);
    };


    useEffect(() => {
        if (assets && assets[0])
            setAsset(assets[0])
    }, [assets]);

    const handleValidDate = (date) => {
        return moment(new Date(date), "YYYY/MM/DD").tz("Asia/Jakarta").format("MMM D, YYYY");
    };

    const sliderSettings = {
        showArrows: true,
        infiniteLoop: true,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };

    return (
        <React.Fragment>
            <div className="layout-wrapper landing">
                <nav className={"navbar navbar-expand-lg navbar-landing fixed-top " + navClass} id="navbar">
                    <Container>
                        <Link className="navbar-brand" to="/">
                            <img src={logodark} className="card-logo card-logo-dark" alt="logo dark" height="60" />
                            <img src={logolight} className="card-logo card-logo-light" alt="logo light" height="60" />
                        </Link>

                        <NavbarToggler className="navbar-toggler py-0 fs-20 text-body" onClick={toggle} type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <i className="mdi mdi-menu"></i>
                        </NavbarToggler>

                        <Collapse isOpen={isOpenMenu} className="navbar-collapse" id="navbarSupportedContent">
                            <Scrollspy
                                offset={-18}
                                currentClassName="active"
                                className="navbar-nav mx-auto mt-2 mt-lg-0 fs-16"
                                id="navbar-example">
                                <li className="nav-item"><NavLink href="/property">View Property</NavLink></li>
                                <li className="nav-item"><NavLink href="/location">Locations</NavLink></li>
                                <li className="nav-item"><NavLink href="/landlord">Business</NavLink></li>
                                <li className="nav-item"><NavLink href="/promo">Promotion</NavLink></li>
                            </Scrollspy>
                            {/* <div className="">
                                <Link to="#" className="btn btn-link me-2 fw-medium text-decoration-none text-dark">Sign
                                    in</Link>
                                <Link to="#" className="btn btn-primary">Sign Up</Link>
                            </div> */}
                            <div className="text-sm-end mt-3 mt-sm-0">
                                <ul className="list-inline mb-0 footer-social-link">
                                    <li className="list-inline-item">
                                        <Link to="https://www.instagram.com/abdihomeid/" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-instagram-line"></i>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="https://www.youtube.com/@abdihome3909" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-youtube-line"></i>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="https://www.tiktok.com/@abdihome" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <img className="avatar-xs" style={{ height: "14px", width: "14px" }} src={tiktok} alt="t" />
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                        </Collapse>
                    </Container>
                </nav>

                <section className="section bg-light pb-5 hero-section" id="hero">
                    <div className="bg-overlay bg-overlay-pattern"></div>
                    <Container>
                        <Row className="pt-5">
                            <Col lg={6}>
                                <div>
                                    <h2>{asset.name}</h2>
                                    <div className="hstack gap-3 flex-wrap">
                                        <div className="text-muted">{asset.asset_client && asset.asset_client.name}</div>
                                        <div className="vr"></div>
                                        <div className="text-muted">Published : <span className="text-body fw-medium">{handleValidDate(asset.createdat)}</span></div>
                                    </div>
                                    <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                                        <div className="text-muted fs-16">
                                            <span className="mdi mdi-star text-warning"></span>
                                            <span className="mdi mdi-star text-warning"></span>
                                            <span className="mdi mdi-star text-warning"></span>
                                            <span className="mdi mdi-star text-warning"></span>
                                            <span className="mdi mdi-star text-warning"></span>
                                        </div>
                                    </div>
                                    <Row className="mt-4 inline">
                                        <Col lg={6} sm={3}>
                                            <div className="p-2 border border-dashed rounded text-center">
                                                <div>
                                                    <p className="text-muted fw-medium mb-1">Monthly  :</p>
                                                    <h5 className="fs-17 text-success mb-0">{asset.asset_location && asset.asset_location[0] ? currencyFormat(asset.asset_location[0].monthly_price) : "-"}</h5>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={6} sm={3}>
                                            <div className="p-2 border border-dashed rounded text-center">
                                                <div>
                                                    <p className="text-muted fw-medium mb-1">Yearly </p>
                                                    <h5 className="fs-17 text-success mb-0">{asset.asset_location && asset.asset_location[0] ? currencyFormat(asset.asset_location[0].yearly_price) : "-"}</h5>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3 me-3">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap gap-3">
                                            {((asset.asset_feature) || []).map((obj, key) => {
                                                const img = obj.asset_feature_detail && obj.asset_feature_detail.asset_feature_image && obj.asset_feature_detail.asset_feature_image.path ? process.env.REACT_APP_API_URL + "/files/" + obj.asset_feature_detail.asset_feature_image.path : "";
                                                return (
                                                    <div key={key} style={{ width: "140px" }} ><img src={img} className="align-bottom me-1" style={{ width: "20px" }} alt="..." /> <span className="job-type">{obj.asset_feature_detail && obj.asset_feature_detail.feature_name}</span></div>
                                                )

                                            })}
                                        </div>

                                    </Row>
                                    <div className="mt-4 p-3 fs-14">
                                        <h5 className="fs-14">Description :</h5>
                                        {ReactHtmlParser(asset.description)}
                                    </div>

                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="sticky-side-div">
                                    <div className="product-img-slider mb-3">
                                        <Swiper navigation={true} thumbs={{ swiper: thumbsSwiper }} className="swiper product-thumbnail-slider p-2 rounded bg-light">
                                            <div className="swiper-wrapper">
                                                {(asset.asset_file ? asset.asset_file.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1) : []).map((f, i) => {
                                                    return (<SwiperSlide key={i}>
                                                        <img src={process.env.REACT_APP_API_URL + "/files/" + f.path} alt="" onClick={() => {
                                                            setCurrentImage(process.env.REACT_APP_API_URL + "/files/" + f.path);
                                                            setModal(true)
                                                        }} className="img-fluid d-block pointer" />
                                                    </SwiperSlide>)
                                                })};
                                            </div>
                                        </Swiper>
                                        <div className="product-nav-slider mt-2">
                                            <Swiper
                                                onSwiper={setThumbsSwiper}
                                                slidesPerView={4}
                                                freeMode={true}
                                                navigation={true}
                                                watchSlidesProgress={true}
                                                spaceBetween={10} className="swiper product-nav-slider mt-2 overflow-hidden">
                                                <div className="swiper-wrapper">
                                                    {asset.asset_file && asset.asset_file.length > 1 && asset.asset_file.map((f, i) => {
                                                        return (<SwiperSlide key={i} className="rounded">
                                                            <div className="nav-slide-item">
                                                                <img src={process.env.REACT_APP_API_URL + "/files/" + f.path} alt="" className="d-block rounded" />
                                                            </div>
                                                        </SwiperSlide>)
                                                    })}
                                                </div>
                                            </Swiper>
                                        </div>
                                    </div>
                                    {/* <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }} >
                                        <Masonry>
                                            {((asset && asset.asset_file) || []).map((obj, key) => {
                                                const img = obj.path ? process.env.REACT_APP_API_URL + "/files/" + obj.path : "";
                                                return (<img src={img} key={key} alt={asset.name} className="me-2 mb-2" />)
                                            }
                                            )}
                                        </Masonry>
                                    </ResponsiveMasonry> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section pb-5 pt-4" id="features">
                    <Container>
                        <Row className="align-items-center">
                            <Slider {...sliderSettings}>

                                {((assetLocations) || []).map((item, key) => {
                                    let img = "";
                                    (item.asset_location_asset.asset_file && item.asset_location_asset.asset_file.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1)).forEach(element => {
                                        if (img === "") img = process.env.REACT_APP_API_URL + "/files/" + element.path;
                                    });
                                    const name = item.asset_location_asset.name;
                                    const district = item.asset_location_address[0] && item.asset_location_address[0].av.vd.name;
                                    return (
                                        <div key={key}>
                                            <Card className="explore-box card-animate me-4">
                                                <div className="bookmark-icon position-absolute top-0 end-0 p-2">
                                                    <button type="button" className="btn btn-icon active" data-bs-toggle="button" aria-pressed="true"><i className="mdi mdi-cards-heart fs-16"></i></button>
                                                </div>
                                                <div className="explore-place-bid-img">
                                                    <img src={img} alt="" className="card-img-top explore-img" />
                                                    <div className="bg-overlay"></div>
                                                    <div className="place-bid-btn">
                                                        <NavLink href={"/detail/" + item.asset_location_asset.id} className="btn btn-success p-3"><i className="ri-eye-line align-bottom me-1"></i> View</NavLink>
                                                    </div>
                                                </div>
                                                <CardBody>
                                                    <p className="text-muted fs-12 mb-0"><i className="ri-map-pin-2-line"></i> {district}</p>
                                                    <h5 style={{ minHeight: "50px" }} className="mb-1"><NavLink href={"/detail/" + item.asset_location_asset.id}>{name}</NavLink></h5>
                                                </CardBody>
                                                <div className="card-footer border-top border-top-dashed">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1 fs-11">
                                                            <i className="ri-price-tag-3-fill  text-warning align-bottom me-1"></i> Mulai dari
                                                        </div>
                                                        <h5 className="flex-shrink-0 fs-14 text-primary mb-0">{currencyFormat(item.monthly_price)}</h5>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    )
                                })}
                            </Slider>

                        </Row>
                    </Container>
                </section>

                <Modal id="showModal" isOpen={modal} centered size="xl">
                    <ModalHeader className="bg-light p-3" toggle={toggleModal}>{asset.name}</ModalHeader>
                    <ModalBody>
                        <div>
                            <img src={currentImage} alt="" className="img-fluid" />
                        </div>
                    </ModalBody>
                </Modal>

                <Fab
                    mainButtonStyles={{ right: -10, bottom: -10, backgroundColor: '#1d8944', boxShadow: '0 2px 4px rgba(0,0,0,0.2)' }}
                    icon={<i className="ri-phone-fill fs-36"></i>}
                    alwaysShowTitle={true}
                >
                    <Action
                        style={{
                            backgroundColor: '#25D366',
                        }}
                        onClick={() => {
                            window.location.href = 'https://api.whatsapp.com/send/?phone=6281233225522&text=Hello+Marketing+Apartement+I+need+some+help+here+%40+ABDIHome+-+Bring+Home+To+You+-+Property+Management+In+Jakarta&type=phone_number&app_absent=0';
                        }}
                        text="April (Apartemen)"><i className=" ri-whatsapp-line fs-36"></i></Action>
                    <Action
                        style={{
                            backgroundColor: '#25D366',
                        }}
                        onClick={() => {
                            window.location.href = 'https://api.whatsapp.com/send/?phone=6287778500955&text=Hello+Marketing+Rumah+Kos+I+need+some+help+here+%40+ABDIHome+-+Bring+Home+To+You+-+Property+Management+In+Jakarta&type=phone_number&app_absent=0';
                        }}
                        text="Alya (Rumah Kos)"><i className=" ri-whatsapp-line fs-36"></i></Action>
                    <Action
                        style={{
                            backgroundColor: '#E1306C',
                        }}
                        onClick={() => {
                            window.location.href = 'https://www.instagram.com/abdihomeid/';
                        }}><i className="ri-instagram-line fs-36"></i></Action>
                    <Action
                        style={{
                            backgroundColor: '#FF0000',
                        }}
                        onClick={() => {
                            window.location.href = 'https://www.youtube.com/@abdihome3909';
                        }}><i className="ri-youtube-line fs-36"></i></Action>
                    <Action
                        style={{
                            backgroundColor: '#FFFFFF',
                        }}
                        onClick={() => {
                            window.location.href = 'https://www.tiktok.com/@abdihome';
                        }}><i className="avatar-xs"><img className="avatar-xs" src={tiktok} alt="t" /></i></Action>

                </Fab>
                <Cta />
                <Footer />
                <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
                    <i className="ri-arrow-up-line"></i>
                </button>
            </div>
        </React.Fragment>
    );
};

export default Index;